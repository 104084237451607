import { Routes, Route } from "react-router-dom";

import Navbar from "./Navbar";
import SecondaryNavbar from "./SecondaryNavbar";
import Banner from "./Banner";
import Contact from "./Contact";
import Loader from "./Loader";
import PageNotFound from "./PageNotFound";
import Webapp from "./Webapp";

import OurBerries from "./pages/OurBerries";
import OurProducts from "./pages/OurProducts";
import OurSites from "./pages/OurSites";
import Gallery from "./pages/Gallery";
import WorkWithUs from "./pages/WorkWithUs";
import SeasonalWork from "./pages/SeasonalWork";

function onReady(callback) {
  var intervalId = window.setInterval(function () {
    if (document.getElementsByTagName("body")[0] !== undefined) {
      window.clearInterval(intervalId);
      callback.call(this);
    }
  }, 500);
}

function setVisible(selector, visible) {
  document.querySelector(selector).style.display = visible ? "block" : "none";
}

onReady(function () {
  setVisible(".main-content", true);
  setVisible("#loader", false);
});

function Main() {
  return (
    <div className="main-content">
      <Navbar />
      <Banner />
      <OurBerries reveal={true} />
      <OurProducts reveal={true} />
      <OurSites reveal={true} />
      <Contact reveal={true} />
    </div>
  );
}

function HomePage() {
  return (
    <>
      <Loader />
      <Main />
    </>
  );
}

function WorkWithUsPage() {
  return (
    <>
      <Loader />
      <div className="main-content">
        <SecondaryNavbar />
        <WorkWithUs />
        <SeasonalWork reveal={true} />
        <Gallery reveal={true} />
        <Contact />
      </div>
    </>
  );
}

function OurBerriesPage() {
  return (
    <>
      <SecondaryNavbar berries={true} />
      <OurBerries />
      <Contact />
    </>
  );
}

function OurProductsPage() {
  return (
    <>
      <SecondaryNavbar products={true} />
      <OurProducts />
      <Contact />
    </>
  );
}

function OurSitesPage() {
  return (
    <>
      <SecondaryNavbar sites={true} />
      <OurSites />
      <Contact />
    </>
  );
}

function NotFoundPage() {
  return (
    <>
      <SecondaryNavbar />
      <PageNotFound />
      <Contact />
    </>
  );
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/berries" element={<OurBerriesPage />} />
      <Route path="/products" element={<OurProductsPage />} />
      <Route path="/sites" element={<OurSitesPage />} />
      <Route path="/work-with-us" element={<WorkWithUsPage />} />
      <Route path="/new-webapp" element={<Webapp />} />
      <Route path="/webapp2" element={<Webapp />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
