// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyBmOzGqzaOFNGRNzRBLa8Z6AMkfbjau1Dk",
  authDomain: "sunshineberries-website.firebaseapp.com",
  projectId: "sunshineberries-website",
  storageBucket: "sunshineberries-website.appspot.com",
  messagingSenderId: "965991287104",
  appId: "1:965991287104:web:b8374c83a900b3ac3ed2a5",
  measurementId: "G-9SPBHTTHMJ",
  databaseURL:
    "https://sunshineberries-website.australia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcVU2AkAAAAADT6faFqsHHd3PTeG79ok_hY-NqD"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export default app;
