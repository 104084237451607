const Product = (props) => {
  return (
    <div className="col-lg-4 d-flex align-items-stretch mb-4">
      <div className="product w-100 bg-white rounded p-4 shadow">
        {props.children}{" "}
      </div>
    </div>
  );
};

function OurProducts(props) {
  return (
    <section id="products" className="ftco-section">
      <div className="img-bg">
        <img
          src="./images/logo-secondary-no-text.png"
          alt="corner-farmhand"
          className="img-corner-right"
        />
      </div>
      <div className={props.reveal ? "container reveal" : "container"}>
        <div className="row justify-content-center">
          <div className="col-lg-12 px-2 pt-3">
            <h2 className="mb-4 heading-section text-center">Our Products</h2>
            <div className="row pt-md-3 ">
              <Product>
                <img
                  src="./images/products-sb.jpg"
                  alt="strawberries"
                  className="shadow-none"
                />
                <h3 className="text-center">Strawberries</h3>
                <p>
                  Strawberries are a perennial plant however, at Tasmanian
                  Berries we replace our strawberries annually.
                </p>
                <p>
                  Tasmanian Berries maintains the strawberry plants by removing
                  runners in the growing season so the plants will produce more
                  flowers for fruit.
                </p>
                <p>
                  Each year, Tasmanian Berries produces Strawberries from early
                  November until May.
                </p>
              </Product>
              <Product>
                <img
                  src="./images/products-rb.jpg"
                  alt="raspberries"
                  className="shadow-none"
                />
                <h3 className="text-center">Raspberries</h3>
                <p>
                  Raspberries are a perennial plant. At Tasmanian Berries we
                  grow our raspberries on four year rotations at which point the
                  plants are replaced.
                </p>
                <p>
                  Tasmanian Berries maintains the raspberry plants by pruning
                  and trellising in both wintertime and summertime, depending on
                  the plant's age, so to grow fruit during the summertime
                  (floracane) and/or the autumn (primacane).
                </p>
                <p>
                  Each year, Tasmanian Berries produces raspberries from late
                  November until May.
                </p>
              </Product>
              <Product>
                <img
                  src="./images/products-bb.jpg"
                  alt="blackberries"
                  className="shadow-none"
                />
                <h3 className="text-center text-primary">Blackberries</h3>
                <p>
                  Tasmanian Berries is one of the largest independent,
                  commercial blackberry producers in Australia.
                </p>
                <p>
                  Blackberries are a perennial plant. At Tasmanian Berries we
                  grow our blackberries on six year rotations at which point the
                  plants are replaced.
                </p>
                <p>
                  Tasmanian Berries maintains the blackberry plants by pruning
                  and trellising in both wintertime and summertime, depending on
                  the plant's age and variety, so to grow fruit during the
                  summertime (floracane) and/or the autumn (primacane).
                </p>
                <p>
                  Each year, Tasmanian Berries produces blackberries from late
                  November until May.
                </p>
              </Product>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurProducts;
