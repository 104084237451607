const PageNotFound = () => {
  return (
    <div className="ftco-banner banner-404 vh-100">
      <div className="container h-100 d-flex flex-column justify-content-center">
        <div className="row banner-text align-items-center justify-content-start">
          <div className="col-lg-12 ">
            <h1 className="animate__animated animate__bounce animate__delay-1s text-white">
              404
            </h1>
            <p className="mb-4">Page Not Found!</p>
            <p>
              <a
                href="/#"
                className="d-inline-flex align-items-center btn btn-primary mt-3 py-2 px-3 rounded-0"
              >
                <span className="me-2 fa fa-chevron-circle-left"></span>
                Back To Homepage
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
