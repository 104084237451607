import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './utilities/firebaseConfig';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';

// const firebase = require('firebase');
// const firebaseui = require('firebaseui');

const Webapp = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        console.log(authResult);
        console.log(authResult.user.email);
        console.log(authResult.user.displayName);

        setUserEmail(authResult.user.email);
        setUserName(authResult.user.displayName);
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        document
          .getElementById('firebaseui-auth-container')
          .classList.add('d-none');
        authResult.user.email.includes('@tasmanianberries.com.au')
          ? document.getElementById('webapp').classList.remove('d-none')
          : document.getElementById('no-access').classList.remove('d-none');

        return true;
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        // document
        //   .getElementById('firebaseui-auth-container')
        //   .classList.remove('d-none');
        // document.getElementById('webapp').classList.add('d-none');
      },
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
  };

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <div className="container bg-light p-3 p-md-5 my-3 my-lg-5">
      <div className="w-100 text-bg-danger bg-gradient my-4 shadow">
        <h1 className="text-center h2 m-0">
          {userName ? `Hello ${userName}!` : 'Tasmanian Berries'}
        </h1>
      </div>
      <div
        id="firebaseui-auth-container"
        className="mt-5 d-flex flex-column align-items-center justify-content-center h5"
      >
        <div>You need to login to access this page. </div>
        <div>Please use your Tasmanian Berries email account.</div>
      </div>
      <div
        id="no-access"
        className="mt-5 d-flex flex-column align-items-center justify-content-center h5 d-none"
      >
        <div className="alert alert-danger" role="alert">
          You have no access to the webapp page!
        </div>
        <div className="text-center p-3">
          Please contact{' '}
          <a href="mailto:ha.nguyen@tasmanianberries.com.au">
            ha.nguyen@tasmanianberries.com.au
          </a>{' '}
          for any inquiries.
        </div>
      </div>
      <div id="webapp" className="row d-none">
        <div className="col-12 col-md-6 col-xl-4 mb-5">
          <div className="border rounded shadow">
            <h3 className="text-bg-dark bg-gradient text-center mb-3">
              Webapp
            </h3>
            <ul>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://script.google.com/a/tasmanianberries.com.au/macros/s/AKfycbwnSUkC4DxSZOvCPWUR52GprqwmH1uHDo7-7ZZRiFChkfVETdk/exec"
                >
                  Dispatch
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/daily-report.html"
                >
                  Daily Report
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/frozen-jam.html"
                >
                  Frozen Jam
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://tasmanianberries.com.au/packaging-order.html"
                >
                  Packaging Order
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfcW3gUtSyVDbJSrJ6R7ASW5htRlra__k8P4BD83IsxQliaFQ/viewform"
                >
                  Visy Receive
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSerMM15zDk-dxfkOxmAobPczwbIGri8xTQUi1dB6lJ25ZeCZQ/viewform"
                >
                  Multisteps Receive
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/webapp/product_score/index.html"
                >
                  QC Score
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/dayoff.html"
                >
                  Dayoff Sheet
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://tasmanianberries.com.au/webapp/order.html"
                >
                  Product Order
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSejtyHrfwKVr8IdT-P7i7jyqmebVgGtuu-Ijm5e8PRoqFAucA/viewform?usp=sf_link"
                >
                  15min Check
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/webapp/calibration/index.html"
                >
                  Daily Calibration
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://script.google.com/a/tasmanianberries.com.au/macros/s/AKfycbyFRKR4W0nF5AmhAujwSWZUTrpUKS0wlnMOVhyP8m8xzcT0kV4/exec"
                >
                  Temperature Test
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/webapp/incident/index.html"
                >
                  Incident Report v1.3
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tasmanianberries.com.au/webapp/irrigation/index.html"
                >
                  Irrigation Monitoring v2.0
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4 mb-5">
          <div className="border rounded shadow">
            <h3 className="text-bg-dark bg-gradient text-center mb-3">
              Google Sheet
            </h3>
            <ul>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/u/0/?tgif=d"
                >
                  Google Sheet
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1dg5IBfYFauQofngunjC4FnmkoZvH3-tK0NT9E7wvOHA/edit#gid=0"
                >
                  Dispatch
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/14VZ1l781ArgXGmdh6llJynTyelb22BsMeQIG6iDObnE/edit#gid=981578595"
                >
                  QC score
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1JenTsyBybs2mPaOWtTYsuEYf7U8F7MviuEzJ9GDV0Ik/edit#gid=1003321223"
                >
                  Dayoff sheet
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1AQMfsZVFfY6fmGmeMqzOLJrF_sD9wrcxRThc6RugStM/edit#gid=0"
                >
                  Product Order
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1xZFJy39faYhMzONpNZUhAHIJAFaBW6d-wEwcUoSuvZ0/edit#gid=559128971"
                >
                  15min Check
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1cHOE-O9lmeT7gWhyQGJSniYfchzUW0iw0Qm992MGAl0/edit#gid=1817852019"
                >
                  Daily Calibration
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/13ERTNbSBnRsiRcyP1H0aPQctsLuy1tHmM3O9XkBLgPg/edit#gid=2109727547"
                >
                  Picker List
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1jaIK7dGo3zzjkL8c-rBIuw3BZeCWNfQrGqOr7sTjNtA/edit#gid=2025362166"
                >
                  Stocktake
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1V9L5-vaOet_GZ4PDv4tRgAtow9A1FieXprXRU-NQwG0/edit#gid=803581912"
                >
                  Frozon berry
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1g14hgyiKIRkZfY9WC3j3LmeTumRVMDeYtFTcJyrB98k/edit#gid=301614683"
                >
                  Irrigation Monitoring
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1Zr2FxcTMtVCjT93BUVAlu6iVC89O36JNR_0M66tnCxg/edit#gid=1337461860"
                >
                  Incident Report
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-4 mb-5">
          <div className="border rounded shadow">
            {' '}
            <h3 className="text-bg-dark bg-gradient text-center mb-3">
              Resources
            </h3>
            <ul>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=uhMNzyyfDek&ab_channel=TasmanianBerries"
                >
                  Induction Video
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=zvz3k1gBg9A&ab_channel=TasmanianBerries"
                >
                  Attendance App Instructions
                </a>
              </li>
              <li>
                <a
                  className="btn btn-outline-dark"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=VQqmJV2LWPY&ab_channel=TasmanianBerries"
                >
                  Email Signature Instructions
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="text-center p-3">
          Please contact{' '}
          <a href="mailto:ha.nguyen@tasmanianberries.com.au">
            ha.nguyen@tasmanianberries.com.au
          </a>{' '}
          for any inquiries.
        </div>
      </div>
    </div>
  );
};

export default Webapp;
