import { useState } from "react";
import { ContactInfo, BusinessHours, Social } from "./utilities/ContactList";
import { TasLogo, BottomLogo, PartnerLogo } from "./utilities/Logo";

const year = new Date().getFullYear();

function Contact(props) {
    const [showTopBtn, setShowTopBtn] = useState(false);

    // When the user scrolls down 500px from the top of the document, show the button
    // window.onscroll = function () {
    //   scrollFunction();
    // };

    window.addEventListener("scroll", scrollFunction);

    function scrollFunction() {
        if (
            document.body.scrollTop > 500 ||
            document.documentElement.scrollTop > 500
        ) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    return (
        <footer
            id="contact"
            className="ftco-footer ftco-section bg-blackberry border-top border-blackberry2"
        >
            <div style={{ display: showTopBtn ? "block" : "none" }}>
                <button
                    onClick={topFunction}
                    id="goToTopBtn"
                    className="bg-transparent d-flex p-2 rounded-0 d-flex flex-column align-items-center justify-content-center"
                    title="Go to top"
                    style={{ fontSize: "0.5rem", zIndex: "9999" }}
                >
                    {/* <i className="material-icons">arrow_upward</i> */}
                    <img
                        src="./images/raspberry.png"
                        alt="corner-farmhand"
                        className=""
                        style={{ width: "4rem" }}
                    />
                    <span style={{ color: "#C0C0C0" }}>BACK TO TOP</span>
                </button>
            </div>
            <div className="img-bg">
                <img
                    src="./images/logo-secondary-no-text.png"
                    alt="corner-farmhand"
                    className="img-corner-bottom-right"
                />
            </div>
            <div className={props.reveal ? "container reveal" : "container"}>
                <div className="row mb-4 mb-lg-3">
                    <div className="col-md-6 col-lg-4 pe-md-4">
                        <div className="ftco-footer-widget mb-4">
                            <BottomLogo />
                            <Social />
                        </div>
                    </div>
                    {/* <div className="col-md-1"></div> */}

                    <div className="col-md-6 col-lg-4 px-md-4 mt-md-5">
                        <div className="ftco-footer-widget mb-4">
                            <BusinessHours />
                        </div>
                        <div className="ftco-footer-widget mb-4">
                            <ContactInfo />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 ps-lg-4 ">
                        <div className="ftco-footer-widget p-3 bg-blackberry border border-blackberry2 border-opacity-50 rounded shadow-lg">
                            <TasLogo />
                        </div>
                    </div>
                </div>
                <div
                    id="partners"
                    className="row bg-blueberry mb-5 mb-lg-4 py-4"
                >
                    <div className="d-flex flex-wrap align-items-center justify-content-center ">
                        <h4 className="text-white p-2 my-2 mx-4">
                            Our Partners
                        </h4>
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <PartnerLogo
                                href={"https://www.primaryemployers.com.au/"}
                                src={"./images/partners-pet.png"}
                            />
                            <PartnerLogo
                                href={"http://www.driscolls.com.au/"}
                                src={"./images/partners-driscolls-2.png"}
                            />
                            <PartnerLogo
                                href={"http://www.fruitgrowerstas.org.au/"}
                                src={"./images/partners-fruitgrowerstas.png"}
                            />
                            <PartnerLogo
                                href={"http://www.elitetunnels.com/"}
                                src={"./images/partners-elitetunnels.png"}
                            />
                            <PartnerLogo
                                href={"http://www.haygrove.com/"}
                                src={"./images/partners-haygrove.png"}
                            />
                            <PartnerLogo
                                href={"https://berries.net.au/"}
                                src={"./images/partners-berriesaustralia.png"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <p>Copyright &copy; {year} Tasmanian Berries Pty Ltd.</p>
                </div>
            </div>
        </footer>
    );
}
export default Contact;
