const toggleIcon = () => {
  const btn = document.getElementById('navbar-toggler-icon');
  if (btn.className === 'fa fa-bars')
    btn.className = 'fa fa-times text-primary';
  else btn.className = 'fa fa-bars';
};

const ToggleButton = () => {
  return (
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={toggleIcon}
    >
      <span id="navbar-toggler-icon" className="fa fa-bars"></span>
    </button>
  );
};

const WorkWithUsButton = () => {
  return (
    <a
      href="/work-with-us"
      target="_blank"
      className="btn btn-primary text-white py-2 px-3 rounded-0 shadow btn-wiggle work-with-us-btn"
    >
      <span className="px-1">Work With Us</span>
      <span className="ms-2 fa fa-chevron-circle-right"></span>
    </a>
  );
};

const BlobButton = (props) => {
  return (
    <a
      key={props.title}
      href={props.link ? props.link : '#'}
      className="button btn-blob rounded-0 shadow-sm p-3 mb-3"
      target="_blank"
      rel="noreferrer"
    >
      <div className="d-flex align-items-center justify-content-between">
        <span>{props.title}</span>
        <span className="fa fa-arrow-right"></span>
      </div>
      <span className="btn-blob__inner">
        <span className="btn-blob__blobs">
          <span className="btn-blob__blob"></span>
          <span className="btn-blob__blob"></span>
          <span className="btn-blob__blob"></span>
          <span className="btn-blob__blob"></span>
        </span>
      </span>
    </a>
  );
};
export { ToggleButton, BlobButton, WorkWithUsButton };
