const NavItem = (props) => {
  return (
    <li className={props.active ? "nav-item active" : "nav-item"}>
      <a
        href={props.href}
        className="nav-link d-flex align-items-center justify-content-start justify-content-lg-center p-2 p-lg-4 h-100"
      >
        {props.name}
      </a>
    </li>
  );
};
export default NavItem;
