const TopLogo = () => {
    return (
        <a className="navbar-brand" href="/#">
            <img
                className="py-2"
                src="./images/logo-secondary-white-text-horizontal.png"
                alt="logo-top"
            />
        </a>
    );
};

const BottomLogo = () => {
    return (
        <>
            <img
                className="mb-3 border-0 rounded-0"
                src="./images/logo-secondary-white-text.png"
                alt="logo-bottom"
            />
            <p>We Value: Safety, Passion, Openness, Respect, and Teamwork</p>
        </>
    );
};

const TasLogo = () => {
    return (
        <div className="d-flex flex-column">
            <a
                href="https://tasmanianberries.com.au/"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    className="mb-3 border-0 rounded-0 tas-logo"
                    src="./images/tb-logo-secondary-white-text.png"
                    alt="logo-bottom"
                />
            </a>
            <p>
                Sunshine Berries is a subsidiary of Tasmanian Berries. We are a
                family owned business commercially producing berries for
                Driscoll’s Australia, in Central Northern Tasmania and the
                Bundaberg Region in Queensland.
            </p>
            <p>
                {/* <u>Website:</u>{" "} */}
                <a
                    href="https://tasmanianberries.com.au/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-primary rounded-0"
                >
                    www.tasmanianberries.com.au
                </a>
            </p>
        </div>
    );
};

const PartnerLogo = (props) => {
    return (
        <a
            href={props.href}
            className="partner-logo"
            target="_blank"
            rel="noreferrer"
        >
            <img src={props.src} alt="" srcSet="" />
        </a>
    );
};
export { TopLogo, BottomLogo, TasLogo, PartnerLogo };
