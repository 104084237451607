function OurSites(props) {
  return (
    <section id="sites" className="ftco-section bg-light">
      <div className="img-bg">
        <img
          src="./images/corner-map.png"
          alt="corner-farmhand"
          className="img-corner-right"
        />
      </div>
      <div className={props.reveal ? "container reveal" : "container"}>
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 text-center">
            <h2>Our Site</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="site-block justify-content-end">
              <iframe
                title="bundaberg-site"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16926.17745072409!2d152.25644165433246!3d-25.166094413474074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6beb07d58f6432ad%3A0x629d74d13f0768c8!2s43%20Knockroe%20Rd%2C%20North%20Isis%20QLD%204660!5e0!3m2!1sen!2sau!4v1680753688830!5m2!1sen!2sau"
                width="100%"
                height="400"
                loading="lazy"
                alt="bundaberg-site"
              ></iframe>
              <div className="text p-4 float-right d-block">
                <div className="topper d-flex align-items-center">
                  <div className="py-2 px-3 align-self-stretch">
                    <span className="site-name">Bundaberg</span>
                  </div>
                </div>
                <p className="site-address">
                  43 Knockroe Rd, North Isis QLD 4660
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="site-block justify-content-end">
              <iframe
                title="ch-site"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47823.941479345696!2d146.597096!3d-41.482679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaa7a68ce9ee77115%3A0xa78d1b7f2e1b3393!2sChristmas%20Hills%20Raspberry%20Farm%20Cafe!5e0!3m2!1sen!2sau!4v1662528474123!5m2!1sen!2sau"
                width="100%"
                height="400"
                loading="lazy"
                alt="ch-site"
              ></iframe>
              <div className="text p-4 float-right d-block">
                <div className="topper d-flex align-items-center">
                  <div className="py-2 px-3 align-self-stretch">
                    <span className="site-name">Christmas Hills</span>
                  </div>
                </div>
                <p className="site-address">
                  9 Christmas Hills Rd, Elizabeth Town TAS 7304
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
export default OurSites;
