import { useState } from "react";

import NavItem from "./utilities/NavItem";
import { TopLogo } from "./utilities/Logo";
import { ToggleButton, WorkWithUsButton } from "./utilities/Button";

function Navbar() {
  const [navbarSize, setNavbarSize] = useState("big");

  // When the user scrolls down 500px from the top of the document, show the button
  // window.onscroll = function () {
  //   navbarSizeUpdate();
  // };

  window.addEventListener("scroll", navbarSizeUpdate);

  function navbarSizeUpdate() {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      setNavbarSize("small");
    } else {
      setNavbarSize("big");
    }
  }

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark bg-blackberry fixed-top ftco-navbar shadow ${navbarSize} ${
        navbarSize === "big" ? "py-3" : "py-1"
      }`}
    >
      <div className="container mx-lg-auto">
        <TopLogo />
        <ToggleButton />
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <NavItem href={"/#"} name={"Home"} />
            <NavItem href={"#berries"} name={"Our Berries"} />
            <NavItem href={"#products"} name={"Our Products"} />
            <NavItem href={"#sites"} name={"Our Site"} />
            <NavItem href={"#contact"} name={"Contact Us"} />

            <li className="animate__animated ms-0 my-3 ms-lg-3 d-none d-lg-flex flex-row flex-wrap align-items-center justify-content-center">
              <WorkWithUsButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
