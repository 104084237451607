import { WorkWithUsButton } from "./utilities/Button";

// Safety, Passion, Openness, Respect, and Teamwork
// const textArray = ["Safety", "Passion", "Openness", "Respect", "Teamwork"];
const textArray = [
  "We Value: Safety, Passion, Openness, Respect, and Teamwork",
];
const typingDelay = 100;
// const erasingDelay = 100;
const newTextDelay = 500; // Delay between current and next text
let textArrayIndex = 0;
let charIndex = 0;

function type() {
  const typedTextSpan = document.querySelector(".typed-text");
  const cursorSpan = document.querySelector(".cursor");
  if (charIndex < textArray[textArrayIndex].length) {
    if (!cursorSpan.classList.contains("typing"))
      cursorSpan.classList.add("typing");
    typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
    charIndex++;
    setTimeout(type, typingDelay);
  } else {
    cursorSpan.classList.remove("typing");
    // cursorSpan.classList.add("d-none");
    // setTimeout(erase, newTextDelay);
  }
}

// function erase() {
//   const typedTextSpan = document.querySelector(".typed-text");
//   const cursorSpan = document.querySelector(".cursor");
//   console.log("typedTextSpan", typedTextSpan);
//   console.log("cursorSpan", cursorSpan);
//   if (charIndex > 0) {
//     if (!cursorSpan.classList.contains("typing"))
//       cursorSpan.classList.add("typing");
//     typedTextSpan.textContent = textArray[textArrayIndex].substring(
//       0,
//       charIndex - 1
//     );
//     charIndex--;
//     setTimeout(erase, erasingDelay);
//   } else {
//     cursorSpan.classList.remove("typing");
//     textArrayIndex++;
//     if (textArrayIndex >= textArray.length) textArrayIndex = 0;
//     setTimeout(type, typingDelay + 1100);
//   }
// }

document.addEventListener("DOMContentLoaded", function () {
  // On DOM Load initiate the effect
  if (textArray.length) setTimeout(type, newTextDelay + 500);
});

function Banner() {
  return (
    <section id="banner">
      <div className="ftco-banner vh-100 ">
        <div className="container h-100 text-center d-flex flex-column justify-content-center align-items-center">
          <div className="row banner-text text-center">
            <h1 className="text-white mb-0">Sunshine Berries</h1>
            <div className="typing-container ">
              <p className="mt-2 mb-4 py-1 px-3 ">
                {/* <i class="fa fa-quote-left me-2" aria-hidden="true"></i> */}
                <span className="typed-text"></span>
                {/* <span className="cursor">&nbsp;</span> */}
                <a
                  href="/work-with-us"
                  target="_blank"
                  className="cursor d-none d-lg-inline"
                >
                  <span className="ms-2 fa fa-chevron-circle-right text-white opacity-75"></span>
                </a>
              </p>
            </div>
            <p className="animate__animated animate__bounceInUp d-flex d-lg-none flex-row flex-wrap align-items-center justify-content-center">
              <WorkWithUsButton />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Banner;
