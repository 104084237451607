function OurBerries(props) {
  return (
    <section
      id="berries"
      className="ftco-section-dark bg-blackberry bg-gradient "
    >
      <div className="img-bg">
        <img
          src="./images/corner-plant.png"
          alt="corner-farmhand"
          className="img-corner-right"
        />
      </div>

      <div className={props.reveal ? "container reveal" : "container"}>
        <h2>Our Berries</h2>
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <h3>Innovative Growing Methods</h3>
            <p>
              All of our berries are grown under multi-bay polytunnels. The
              polytunnels allow the growing season to extend beyond traditional
              growing periods by creating an ideal micro-climate, different to
              that outside of the polytunnels. The polytunnels allow pollinators
              and other beneficial insects to thrive as well as protecting the
              berries from weather events, including rain, wind, hail or sunburn
              and maximising sunlight and heat to create an ideal growing
              environment for our premium berries.
            </p>
            <p>
              In addition to growing under the polytunnels, the majority of our
              berries are grown hydroponically, which means that they are grown
              in individual bags of coir (coconut peat). The plants are fed
              through a state of the art irrigation system, which provides the
              plants with the specific nutrients each variety requires for
              maximum yield and quality.
            </p>

            <img
              src="./images/berries-planting-1.jpg"
              alt="berries-planting-1"
            />

            <p>
              Our strawberry plants are grown on tabletop infrastructure,
              raising the plants up off the ground to a height of approximately
              1.2 metres. This allows pickers to easily see and access the
              berries when picking as well as helping to control pests and
              diseases which traditionally attack berries grown on the ground.
            </p>

            <img
              src="./images/berries-planting-2.jpg"
              alt="berries-planting-2"
            />
          </div>

          <div className="col-lg-7">
            <h3>Plant Varieties</h3>
            <p>
              The majority of the berries grown by Tasmanian Berries are
              Driscoll's varieties. Driscoll's have developed many different
              berry plant varieties, which are grown worldwide by independent
              growers, like Tasmanian Berries.
            </p>
            <p>
              The varieties start life in Driscoll's test plots in various
              growing locations around the world. The seedlings are grown in
              isolation to protect them from pests and diseases and if
              successful are then developed further in Driscoll's nurseries
              before being farmed for commercial production. In the wintertime,
              the seedlings arrive at the farm in cool storage to maintain a
              hibernating state until planting. The seedlings are planted in
              late winter and production of fruit will commence within a few
              months after planting.
            </p>

            <img
              src="./images/berries-planting-3.jpg"
              alt="berries-planting-3"
            />

            <h3>Plant Health</h3>
            <p>
              Tasmanian Berries prides itself on growing healthy plants and in
              turn premium quality berries. We monitor our plants on a daily
              basis to maintain plant health and berry quality. During the
              winter months, the cane plants are exposed to frost to allow the
              canes to be chilled, pruned and trellised ready for the new
              season. Strawberry plants are replaced each year to ensure yield
              and quality are maintained throughout the growing season.
            </p>

            <img
              src="./images/berries-planting-4.jpg"
              alt="berries-planting-4"
            />

            <h3>Harvesting</h3>
            <p>
              Throughout the growing season our berries are handpicked,
              generally every second day. The berries are picked and packed in
              the field and immediately delivered to the cool room, where they
              are blast-chilled down to a temperature of 2 - 3 degrees Celsius,
              to maximise quality and shelf life.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurBerries;
